import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [];

export const dictionary = {
		"/[[lang=locale]]/(index-page)": [10,[2],[3]],
		"/[[lang=locale]]/(need-unauth)/account-recovery": [16,[2,8],[3]],
		"/[[lang=locale]]/(non-auth)/like-reload": [21,[2],[3]],
		"/[[lang=locale]]/(need-auth)/logout": [15,[2,4],[3]],
		"/[[lang=locale]]/(need-unauth)/reset-password-from-email": [17,[2,8],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-in": [18,[2,8,9],[3]],
		"/[[lang=locale]]/(need-unauth)/sign-up": [19,[2,8],[3]],
		"/[[lang=locale]]/(need-unauth)/super-admin": [20,[2,8],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]": [11,[2,4,5],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(withSidebar)/business-agent": [12,[2,4,5,6],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(withSidebar)/business-agent/admin": [13,[2,4,5,6],[3]],
		"/[[lang=locale]]/(need-auth)/[workspaceName]/(withSidebar)/similar-advisory": [14,[2,4,5,7],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';